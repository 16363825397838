<footer class="bg-dark text-center text-white">
    <div class="footer-area">
        <div class="">
            <div class="row">
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <div class="footer-content">
                        <div class="footer-head">
                            <div>
                                <a href="#"><img src="assets/img/logo/logo.png" class="footer-logo p-2" alt=""></a>
                            </div>
                            <p>
                                PantaleonTec es una empresa peruana
                                especializada en proyectos exitosos
                                y confiables , con un fuerte compromiso hacia
                                los clientes.
                            </p>
                            
                            <div class="footer-business-address">
                                <p>
                                    
                                </p>
                                <P>Lambayeque, Perú.</P>
                            </div>
                            <div class="footer-phone">
                                <p>+51 951664327</p>
                            </div>
                            <div class="footer-mail">
                                <p>info@pantaleontec.com</p>
                            </div>
                            <div class="footer-web">
                                <p>www.pantaleontec.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- single footer -->
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <div class="footer-content">
                        <div class="footer-head">
                            <h4>Servicios</h4>
                            <ul class="footer-list">
                                <!-- <li><a mat-button [routerLink]="['']">Facturación Electronica</a></li>
                                <li><a mat-button [routerLink]="['']">Punto de Venta</a></li> -->
                                <li><a mat-button [routerLink]="['']">Desarrollo de aplicaciones Web</a></li>
                                <li><a mat-button [routerLink]="['']" class="text-wrap">Desarrollo de aplicaciones móviles</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- end single footer -->
                <!-- single footer -->
                <div class="col-md-2 col-sm-12 col-xs-12">
                    <div class="footer-content">
                        <div class="footer-head">
                            <h4>Empresa</h4>
                            <ul class="footer-list">
                                <li><a mat-button [routerLink]="['']">Inicio</a></li>
                                <li><a mat-button [routerLink]="['about']">Nosotros</a></li>
                                <!-- <li><a mat-button [routerLink]="['directorio']" class="text-wrap">Directorio de Empresas</a></li> -->
                                <li><a mat-button [routerLink]="['contactenos']">Contactanos</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- end single footer -->
                <div class="col-md-2 col-sm-12 col-xs-12">
                    <div class="footer-content">
                        <div class="footer-head">
                            <h4>Atención:</h4>
                            <ul class="footer-list">
                                <li>
                                    <p>
                                        Lun - Vie: 08:00
                                        - 17:00
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="footer-icons">
                        <h6>Siguenos: <a href="#"><mat-icon>facebook</mat-icon></a></h6>
                        
                    </div>
                </div>
                <!-- end single footer -->
            </div>
        </div>
    </div>
    <div class="footer-area-bottom">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-xs-12">
                    <div class="copyright">
                        <p>
                            Copyright © 2022
                            <a href="#">PantaleonTec</a> Todos los derechos reservados.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>