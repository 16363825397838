<div class="single-services-page area-padding">
    <div class="container">
        <!-- Start service page -->
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="row">
                <!-- single-well end-->
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="single-well">
                        <a href="#">
                            <h3>PANTALEONTEC</h3>
                        </a>
                        <div class="col-sm-5 row" style="margin-right: 10px">
                            <div class="single-page">

                            </div>
                        </div>

                        <p>
                            Somos profesionales peruanos comprometidos con tu negocio, con mas de 10 años de
                            experiencia,
                            especializados en soluciones tecnológicas estratégicas para pequeñas, medianas y grandes
                            empresas, especializados en proyectos integrales confiables y exitosos.
                            La pasión de nuestro trabajo y el fuerte compromiso que tenemos con nuestros clientes nos
                            permiten
                            mejorar continuamente para diseñar e implementar procesos de negocios mas productivos y
                            eficientes.
                        </p>
                        <p>
                            PantaleonTec personaliza soluciones integrales diseñadas para satisfacer sus necesidades
                            inmediatas,
                            al tiempo que considera los requisitos tecnológicos futuros.
                        </p>
                        <p>
                            Como empresa, nuestro objetivo es eliminar los obstáculos y las frustraciones involucradas
                            con la creación e implementación de una base de infraestructura sólida, creando entorno
                            orientado
                            a resultados donde nacen y se ejecutan las mejores soluciones.
                            Somos su brújula tecnológica: estableciendo su negocio en el camino hacia el éxito y la
                            eficiencia.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mar-row pb-5">
                <!-- End single page -->
                <div class="col-md-12 col-sm-12 col-xs-12">
                    
                    <mat-tab-group>
                        <mat-tab label="Misión">
                            <div class="pt-3">
                                Tranformar procesos de negocios digitalmente, alineados a los objetivos de nuestros
                                clientes, logrando reducir costos y optimizar tiempo. Mediante un equipo de profesionales
                                altamente competitivo,
                                utilizando herramientas tecnológicas modernas e innovadoras.
                                </div>
                        </mat-tab>
                        <mat-tab label="Visión"> 
                            <div class="pt-3">
                                Consolidarnos como la plataforma de negocios empresariales que gestione
                                todos los procesos del negocio
                                mejorando la productividad e incrementando sus utilidades.
                            </div>
                        </mat-tab>
                        <mat-tab label="Nuestros Valores"> 
                            <div class="pt-3">
                                <p>Los valores que abrazamos son:</p>
                                <ul class="marker-list">
                                    <li>Calidad</li>
                                    <li>Confidencialidad</li>
                                    <li>Ética</li>
                                    <li>Innovación</li>
                                    <li>Integridad</li>
                                    <li>Mejora continua</li>
                                    <li>Trabajo en equipo</li>
                                </ul>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                    
                </div>
                <!-- end column -->
            </div>
            <!-- end Row -->
            <!-- end Row -->

        </div>
    </div>
</div>