<div class="area-directory-header">
  <!-- <img src="../../../assets/img/foto1.jpg"> -->
  <div class="container">
    <div class="row justify-content-around">
      <div class="col-md-6 col-sm-12"
        style="background-color: yellow;color: blue;padding-left: 5px;padding-top: 15px;padding-right: 5px;padding-bottom: 2px;margin-bottom: 10px;text-align: center;">
        <h6>Regístrate y aprovecha ésta oportunidad para publicar hasta 2 productos gratis, y forma parte de
          nuestro directorio digital!!</h6>
        <a color="accent" [routerLink]="['registrar']" title="Resgístrate gratis">Click aquí</a>
      </div>
    </div>
    <div class="row justify-content-around">

      <div class="form-search-wrap col-md-8 col-sm-12">
        <form [formGroup]="formBusqueda" class="form-content" (ngSubmit)="postBuscar(formBusqueda.value)"
          novalidate="novalidate">


          <!--Main Form-->
          <div class="main-search-form">
            <div class="form-row">
              <!--end col-md-3-->
              <div class="col-md-5 col-sm-12">
                <mat-form-field class="ancho-100p">
                  <mat-label>Buscar por</mat-label>
                  <mat-select [(ngModel)]="selectedValue" formControlName="tipo">
                    <mat-option *ngFor="let item of tipoBusqueda" [value]="item.value">
                      {{item.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!--end form-group-->
              </div>
              <div class="col-md-5 col-sm-12">
                <mat-form-field class="ancho-100p">
                  <mat-label>Digite el nombre a buscar</mat-label>
                  <input matInput type="text" formControlName="buscar">
                  <button *ngIf="buscar" matSuffix mat-icon-button aria-label="Clear" (click)="buscar=''">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <!--end form-group-->
              </div>
              <!--end col-md-3-->
              <div class="col-md-2 col-sm-12">
                <!-- <mat-spinner-button 
                  [options]="spinnerButtonOptions"
                  (btnClick)="postBuscar(formBusqueda.value)">
                </mat-spinner-button> -->
                <button type="submit" [disabled]="!formBusqueda.valid" class="btn btn-primary"
                  style="width:100%">Buscar</button>
              </div>
              <!--end col-md-3-->
            </div>
            <!--end form-row-->
          </div>
          <!--end main-search-form-->



          <!--end container-->
        </form>
      </div>
    </div>
    <div class="row container-card">
      <div class="col-sm-12">
        <div *ngIf="directorio!=undefined">
          <mat-card class="float-card card-catalogo" *ngFor="let item of directorio">
            <mat-card-subtitle>{{item.nomane}}</mat-card-subtitle>
            <mat-card-content>
              <div [innerHTML]="item.contenidohtml"></div>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngIf="catalogo!=undefined">
          <mat-card class="float-card card-catalogo" *ngFor="let item of catalogo">
            <mat-card-title-group>
              <mat-card-title *ngIf="item.tipocatalogoid==1">Producto</mat-card-title>
              <mat-card-title *ngIf="item.tipocatalogoid==2">Servicio</mat-card-title>
              <mat-card-subtitle>{{item.descripcion}}</mat-card-subtitle>
              <!-- <img mat-card-sm-image src="../../../../assets/sin-productos.png"> -->
            </mat-card-title-group>
            <mat-card-content>
              <mat-chip-list *ngIf="item.flagverprevta">
                <mat-chip *ngIf="item.prevtaantes>0" color="accent" selected>Ahora: {{item.monedasimbolo}}
                  {{formatDecimal(item.prevtaahora)}}</mat-chip>
                <mat-chip *ngIf="item.prevtaantes<=0" color="accent" selected>{{item.monedasimbolo}}
                  {{formatDecimal(item.prevtaahora)}}</mat-chip>
                <mat-chip *ngIf="item.prevtaantes>0" color="warn" selected>Antes: {{item.monedasimbolo}}
                  {{formatDecimal(item.prevtaantes)}}</mat-chip>
              </mat-chip-list>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngIf="catalogo==undefined && directorio==undefined">
          <mat-card class="info-sin-resultado">
            <mat-card-content>
              <mat-icon class="icon-medium">search</mat-icon>
              <h3>SIN RESULTADO</h3>
              <h4>Seleccionar el tipo de busqueda y texto a buscar</h4>
              En la parte superior podrá seleccionar el filtro necesario para consultar por negocios o productos

            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>


  </div>
</div>


<!-- End main content -->