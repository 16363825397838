import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor(private route:Router) { }
  
  confirm(message: string, okCallback: () => any) {
    alertify.set('notifier','position', 'top-right');
    alertify.confirm(message, function(e:any) {
      if (e) {
        okCallback();
      }
    });
  }

  success(message: string) {
    alertify.set('notifier','position', 'top-center');
    alertify.success(message);
  }

  error(message: string) {
    alertify.set('notifier','position', 'top-center');
    alertify.error(message);
  }

  warning(message: string) {
    alertify.set('notifier','position', 'top-center');
    alertify.warning(message)
  }

  message(message: string) {
    alertify.message(message)
  }
  showMessage(result:any){

    if(result.resulttype=="ok") this.route.navigate(['/message/'+result.resulttype+'/'+result.resulttext]);
    else if(result.resulttype=="nok") this.error(result.resulttext);
    // else if(result.resulttype=="warning") this.warning(result.resulttext);
    // else if(result.resulttype=="info") this.message(result.resulttext);
  }
}