<!-- Start Slider Area -->
<div>
    <div class="main-overly"></div>
    <div>
        <div class="intro-content">

            <div class="slider-content">
                <div class="display-table">
                    <div class="display-table-cell">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- layer 1 -->
                                    <!-- <div class="layer-1-2">
                                        <span class="title2">Expertos en <span class="color">Tecnología</span></span>
                                    </div> -->
                                    <!-- layer 2 -->
                                    <!-- <div class="layer-1-1 ">
                                        <p>Le brindamos nuestra Plataforma de Gestión de Negocios para que logres el
                                            éxito de tu empresa.</p>
                                        <p>Regístrate con nosotros para ser parte de esta nueva plataforma digital y
                                            logres el cambio que tu negocio necesita. </p>
                                    </div> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="intro-content">

            <div class="slider-content">
                <div class="display-table">
                    <div class="display-table-cell">
                        <div class="container">
                            <div class="row">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Slider Area -->
<!-- about-area start -->
<!--<section class="wow slideInLeft" > Animate css-->
<section id="Nosotros" class="section">
    <!-- two animation-->
    <div class="about-area area-padding" data-aos="fade-left">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="about-image" data-aos="fade-up">
                        <img src="../../../assets/img/img_about.jpg">
                    </div>
                </div>
                <!-- column end -->
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="about-content">
                        <div class="layer-1-2">
                            <h5>Expertos en Tecnología</h5>
                        </div>
                        <p>
                            Somos profesionales peruanos comprometidos con tu negocio,
                            especializados en soluciones tecnológicas estratégicas para pequeñas, medianas y grandes
                            empresas,
                            La pasión de nuestro trabajo y el fuerte compromiso que tenemos con nuestros clientes nos
                            permiten
                            mejorar continuamente para diseñar e implementar procesos de negocios mas productivos y
                            eficientes.
                        </p>
                        <p>
                            PantaleonTec personaliza soluciones integrales diseñadas para satisfacer sus necesidades
                            inmediatas,
                            al tiempo que considera los requisitos tecnológicos futuros.
                        </p>
                        <p>
                            Como empresa, nuestro objetivo es eliminar los obstáculos y las frustraciones involucradas
                            con la creación e implementación de una base de infraestructura sólida, creando entorno
                            orientado
                            a resultados donde nacen y se ejecutan las mejores soluciones.
                            Somos su brújula tecnológica: estableciendo su negocio en el camino hacia el éxito y la
                            eficiencia.
                        </p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>

<!-- about-area end -->
<!--<section >-->
<section data-aos="fade-right" data-aos-duration="1300">
    <!-- about 1-->
    <div class="welcome-area area-padding">
        <div class="container">
            <div class="row">

                <!-- column end -->
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="about-content">
                        <h4>Por qué<span class="color"> nosotros</span>..?</h4>
                        <p>En un mundo de transformación digital las empresas están obligadas a innovar para crecer.
                            Nosotros te ofrecemos ese valor agregado que necesitas para que tu negocio cambie.</p>
                        <div class="about-details text-center">
                            <div class="single-about" data-aos="fade-up">
                                <div class="icon-title">
                                    <a href="#"><i class="icon icon-gift"></i></a>
                                    <h5>Calidad</h5>
                                </div>
                                <p>Trabajamos con altos estándares de gestión de procesos,
                                    mejora continua y buenas prácticas en el desarrollo de nuestros sistemas
                                    informáticos. </p>
                            </div>
                            <div class="single-about" data-aos="fade-up">
                                <div class="icon-title">
                                    <a href="#"><i class="icon icon-thumbs-up"></i></a>
                                    <h5>Experiencia</h5>
                                </div>
                                <p>Mas de 10 años de experiencia en los sectores: Ventas, Inventarios, Importaciones,
                                    Minería, Salud, entre otros. Te brindarán el respaldo que necesitas.</p>
                            </div>
                            <div class="single-about" data-aos="fade-up">
                                <div class="icon-title">
                                    <a href="#"><i class="icon icon-thumbs-up"></i></a>
                                    <h5>Seguridad</h5>
                                </div>
                                <p>Nuestros servicios se respaldan en la Nube y se almacenan en diferentes regiones del
                                    mundo. Por lo que te aseguramos proteger tu información. </p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                    <div class="about-image" data-aos="fade-up">
                        <img src="../../../assets/img/img_about_2.jpeg">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- about 1 end -->
</section>

