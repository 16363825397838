<section class="page_404">
	<div class="container">
		<div class="row">	
		<div class="col-sm-12 ">
		<div class="col-sm-10 col-sm-offset-1  text-center">
		<div class="four_zero_four_bg">
			<h1 class="text-center ">404</h1>
		
		
		</div>
		
		<div class="contant_box_404">
		<h3 class="h2">
		Página no encontrada.
		</h3>
		
		<p>La página que estás buscando no está disponible!</p>
		
		<a class="nav-link" [routerLink]="['']">Ir a la página de inicio</a>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>