import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/guard/storage.service';
import { LoginI } from 'src/app/models/login.interface';
import { CuentaApiService } from 'src/app/services/cuentaapi.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup={} as any;
  public submitted: Boolean = false;
  public error: {code: number, message: string} = {} as any;
  loading = false;

  constructor(private formBuilder: FormBuilder,
              private cuentaService: CuentaApiService,
              private storageService:StorageService,
              private router: Router) { 
                this.loginForm = this.formBuilder.group({
                  username: ['', Validators.required],
                  password: ['', Validators.required],
                })
              }

  ngOnInit() {
    
  }

  public submitLogin(login:LoginI): void {
    this.submitted = true;
    this.loading = true;
    this.error = {} as any;
    if(this.loginForm.valid){
      this.cuentaService.postLogin(login).subscribe(
        data => this.correctLogin(data),
        error => {

          if(error.status) {
            this.error = {code:0,message:error.message};
          }
          else{
            this.error = {code:0,message:"Unknow error"};
          }
          
          this.loading = false;
        }
      )
    }
  }

  private correctLogin(data: any){
    
   if(data.status=="nok"){
     this.error.message=data.message;
   }
   else{
    this.storageService.setAuthenticate(data.token);
    this.router.navigate(['/dashboard'],{replaceUrl: true});
   }
   this.loading = false;
  }
}
