import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalVariable } from "../global";

@Injectable()
export class CursosService{
    constructor(private _http:HttpClient){
    }
    postListar():Observable<string>{
        return this._http.post<string>(GlobalVariable.CURSOS.LISTAR,{});
    }
}