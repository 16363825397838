import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactenosModule } from './contactenos/contactenos.module';
import { DirectorioModule } from './directorio/directorio.module';
import { HomeModule } from './home/home.module';
import { ShareModule } from './share/share.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientModule} from '@angular/common/http';
//Services
import { DataapiService } from './services/dataapi.service';
import { AuthService } from './services/auth.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertifyService } from './services/alertify.service';
import { PortafolioModule } from './portafolio/portafolio.module';
import { VisitanteService } from './services/visitante.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AdminLoginModule } from './admin/admin.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    ShareModule,
    HomeModule,
    DirectorioModule,
    ContactenosModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PortafolioModule,
    AdminLoginModule
  ],
  providers: [
    VisitanteService,
    { provide: 'LOCALSTORAGE', useValue: window.localStorage },
    DataapiService,{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthService,
    multi: true
  },
  AlertifyService,
  {
    provide: LocationStrategy, useClass: HashLocationStrategy
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
