import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ResponseI } from '../models/response.interface';
import { ContrasenaI } from '../models/contrasena.interface';
import { LoginI } from '../models/login.interface';
import { GlobalVariable } from '../global';

@Injectable({
  providedIn: 'root'
})
export class CuentaApiService {

  constructor(private http:HttpClient) { 

    http.get("../../assets/token.json").subscribe(resp=>{
      
      localStorage.setItem("token",resp["token"]);
    })

  }

  postCuentaConfirmar(id:String,cuenta:String){
    
    const url_api=GlobalVariable.CUENTA.CONFIRMAR+'?id='+id+'&cuenta='+cuenta;
    return this.http.post<string>(url_api,null);
  }
  postValidarSolicitud(id:String){
    
    const url_api=GlobalVariable.CUENTA.VALIDARSOLICITUD+'?id='+id;
    return this.http.post<string>(url_api,null);
  }
  postSolicitar(correo:String){
    
    const url_api=GlobalVariable.CUENTA.RECUPERARPASSWORD+'?correo='+correo;
    return this.http.post<string>(url_api,null);
  }
  postCambiarContrasena(form:ContrasenaI){
    const url_api=GlobalVariable.CUENTA.CAMBIARCONTRASENA+'?idsolicitud='+form.idSolicitud+'&id='+form.id.toString()+'&contrasena='+form.contrasena;
    
    return this.http.post<string>(url_api,form);
  }
  postLogin(form:LoginI){
    const url_api=GlobalVariable.CUENTA.LOGIN;
    
    return this.http.post<any>(url_api,form);
  }
  
  
}
