import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { VisitanteService } from './services/visitante.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'PantaleonTec';
  constructor(private http:HttpClient,private _visitante:VisitanteService){
  }
}
