import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertifyService } from 'src/app/services/alertify.service';
import { ContactenosService } from 'src/app/services/contactenos.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class ContactenosIndexComponent implements OnInit {

  formContacto:FormGroup;
  loading=false;

  constructor(private _contactenosService:ContactenosService,
    private _formBuilder:FormBuilder,
    private _alert:AlertifyService) { 

    this.formContacto = this._formBuilder.group({
      nombre:['',[Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', []],
      mensaje: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  
  postEnviar(form: FormGroup){
    this.loading=true;
    this._contactenosService.postEnviar(form).subscribe((data)=>{
      
      this.showResult(data);
      
    },
    errorObject=>{
      this._alert.error(errorObject.error.resulttext);
      this.loading=false;
    })
  }

  showResult(data:any){
    
    this.loading=false;
    if(data.resulttype=="ok") {
      this._alert.success(data.resulttext);
      this.formContacto.reset();
    }
    else
      this._alert.showMessage(data);
    
  }
}
