import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { CuentaClienteI } from '../models/cuentacliente.interface';
import { CuentaEmpresaI } from '../models/cuentaempresa.interface';
import { ResponseI } from '../models/response.interface';
import { GlobalVariable } from '../global';

@Injectable({
  providedIn: 'root'
})
export class DataapiService {

  constructor(private http:HttpClient) { 

  }

  getDatosPorRUc(ruc:String):Observable<string>{
    const url_api= GlobalVariable.PADRON.OBTENER+ '?ruc='+ruc;
    return this.http.get<string>(url_api,{});
  }
  postClienteGuardar(form:CuentaClienteI){
    const url_api=GlobalVariable.CUENTA.CLIENTEGUARDAR;
    return this.http.post<string>(url_api,form);
  }
  postEmpresaGuardar(form:CuentaEmpresaI){
    const url_api=GlobalVariable.CUENTA.EMPRESAGUARDAR;
    return this.http.post<string>(url_api,form);
  }
}
