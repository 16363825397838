import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalVariable } from "../global";

@Injectable()
export class ContactenosService{
    constructor(private _http:HttpClient){
    }
    postEnviar(data:any):Observable<any>{
        return this._http.post<any>(GlobalVariable.CONTACTENOS.ENVIAR,data);
    }
    postGetAll():Observable<any>{
        return this._http.post<any>(GlobalVariable.CONTACTENOS.GETALL,{});
    }
}