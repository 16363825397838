import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalVariable } from "../global";

@Injectable()
export class CatalogoService{
    constructor(private _http:HttpClient){
    }
    postListar(buscar:string):Observable<string>{
        return this._http.post<string>(GlobalVariable.CATALOGO.LISTAR+"?buscar="+buscar,{});
    }
}