import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-puntoventa',
  templateUrl: './puntoventa.component.html',
  styleUrls: ['./puntoventa.component.scss']
})
export class PuntoventaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
