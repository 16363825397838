import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesarrolloappComponent } from './desarrolloapp/desarrolloapp.component';
import { CapacitacionesComponent } from './capacitaciones/capacitaciones.component';
import { PortafolioRoutingModule } from './portafolio-routing.module';
import {MatCardModule} from '@angular/material/card';
import { DesarrollowebComponent } from './desarrolloweb/desarrolloweb.component';
import { EfacturaComponent } from './efactura/efactura.component';
import { PuntoventaComponent } from './puntoventa/puntoventa.component';
import { CursosService } from '../services/cursos.service';

@NgModule({
  declarations: [DesarrolloappComponent, CapacitacionesComponent, DesarrollowebComponent, EfacturaComponent, PuntoventaComponent],
  imports: [
    CommonModule,
    PortafolioRoutingModule,
    MatCardModule
  ],
  providers:[CursosService]
})
export class PortafolioModule { }
