import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalVariable } from "../global";

@Injectable()
export class VisitanteService{
    constructor(private _http:HttpClient){}

    postEnviar(page_name:any):Observable<any>{
        return this._http.post<any>(GlobalVariable.VISITANTE.ENVIAR+"?pagename="+page_name,{});
    }
    postLogs():Observable<any>{
        return this._http.post<any>(GlobalVariable.VISITANTE.LOGS,{});
    }
}