import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class AuthService implements HttpInterceptor {
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token=localStorage.getItem("token");
    
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer '+token
      }
    });

    return next.handle(req);
  }
}