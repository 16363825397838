import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GlobalVariable } from "../global";

@Injectable()
export class DirectorioService{
    constructor(private _http:HttpClient){

    }
    postListar(buscar:string){
        return this._http.post<string>(GlobalVariable.DIRECTORIO.LISTAR+"?buscar="+buscar,{});
    }
}