import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { Page404Component } from './page404/page404.component';
import { AlertComponent } from './alert/alert.component';
import {MatListModule} from '@angular/material/list';

@NgModule({
  declarations: [HeaderComponent, NavbarComponent, FooterComponent, Page404Component, AlertComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule
  ],
  exports:[
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    Page404Component,
    AlertComponent

  ]
})
export class ShareModule { }
