import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl,Validators } from '@angular/forms';
import { DataapiService } from 'src/app/services/dataapi.service';
import { CuentaClienteI } from '../../models/cuentacliente.interface';
import { CuentaEmpresaI } from '../../models/cuentaempresa.interface';
import { AlertifyService } from 'src/app/services/alertify.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrarComponent implements OnInit {

  
  constructor(private api:DataapiService,private formBuilder:FormBuilder, 
    private alert:AlertifyService,private route:Router ) { }

  checked = false;
  registrarCuentaCliente:FormGroup;
  registrarCuentaEmpresa:FormGroup;
  correo = new FormControl('', [Validators.required, Validators.email]);
  hide = true;

  ngOnInit(): void {
    //localStorage.setItem('dataSource', );
    
    //this.postPadron("10176402968");
    this.registrarCuentaCliente=this.formBuilder.group({
      nombres:['',[Validators.required]],
      apelpat:[''],
      apelmat:[''],
      telefono:[''],
      movil:[''],
      correo:['',[Validators.required]],
      contrasena:['',[Validators.required, Validators.minLength(6)]],
      repetirContrasena: [null, Validators.compose([Validators.required])],
      flagTerminos: new FormControl('', [Validators.requiredTrue])
    },{validator:this.RepeatPasswordValidator});

    this.registrarCuentaEmpresa=this.formBuilder.group({
      ruc:['',[Validators.required]],
      razonSocial:['',[Validators.required]],
      direccion:[''],
      distrito:[''],
      telefono:[''],
      movil:[''],
      correo:['',[Validators.required,Validators.email]],
      contrasena:['',[Validators.required, Validators.minLength(6)]],
      repetirContrasena: [null, Validators.compose([Validators.required])],
      flagTerminos: ['', [Validators.requiredTrue]]
    },{validator:this.RepeatPasswordValidator});
  }

  postPadron(ruc:String){
    this.api.getDatosPorRUc(ruc).subscribe((data)=>this.verDataPadron(data),
    (error: any) => {
        this.alert.error("Error desconocido al conectarse con el servicio.");
    });
  }

  postFormCliente(form: CuentaClienteI){
    this.api.postClienteGuardar(form).subscribe((data)=>{
      
      this.showResult(JSON.parse(data));
    });
  }
  postFormEmpresa(form: CuentaEmpresaI){
    this.api.postEmpresaGuardar(form).subscribe((data)=>{
      
      this.showResult(data);
      
    },
    error=>{
      console.log(error);
      this.alert.error("Error desconocido al conectarse con el servicio.");
    })
  }

  showResult(data:any){
    
    if(data.resulttype=="success") this.route.navigate(['/message/'+data.resulttype+'/'+data.resulttext]); //this.alertPage.showMessage(data);
    else this.alert.showMessage(data);
  }
  RepeatPasswordValidator(group: FormGroup) {
    let password = group.controls.contrasena.value;
    let passwordConfirmation = group.controls.repetirContrasena.value;
  
    return password === passwordConfirmation ? null : { passwordsNotEqual: true }     

  }
  verDataPadron(data: any){
    {
      
      if(data==null || data==""){
        this.alert.error("El RUC no se encuentra en el padrón de contribuyentes de la SUNAT.");
      }
      else{
        var padron=data;//JSON.parse(data);
        this.registrarCuentaEmpresa.get('ruc').setValue(padron.ruc);
        this.registrarCuentaEmpresa.get('razonSocial').setValue(padron.nombre);
        this.registrarCuentaEmpresa.get('distrito').setValue(padron.ubigeo);
        this.registrarCuentaEmpresa.get('direccion').setValue(padron.tipvia+' '+padron.nomvia+' '+padron.numvia+' '+padron.kmdir+' '+padron.mzdir+' '+padron.lote);
      }
      //   'direccion':data.TipVia+' '+data.NomVia+' '+data.NumVia+' '+data.KmDir+' '+data.MzDir+' '+data.Lote,
    }
  }
  onKeyDownRuc(event:any){
    this.registrarCuentaEmpresa.controls['razonSocial'].setValue('');
    this.registrarCuentaEmpresa.get('distrito').setValue("");
    this.registrarCuentaEmpresa.get('direccion').setValue("");
  }
}
