import { environment as INV } from "src/environments/environment";
export const GlobalVariable = Object.freeze({
    CATALOGO:{
        LISTAR:INV.apiUrl+'api/catalogo'
    },
    PADRON:{
        OBTENER:INV.apiUrl+'api/Padron/ObtenerPorRuc'
    },
    CUENTA:{
        CLIENTEGUARDAR:INV.apiUrl+'api/cuenta/emprendedor/guardar',
        EMPRESAGUARDAR:INV.apiUrl+'api/cuenta/empresa/guardar',
        CONFIRMAR:INV.apiUrl+'api/Cuenta/Confirmar',
        VALIDARSOLICITUD:INV.apiUrl+'api/Cuenta/ValidarSolicitud',
        RECUPERARPASSWORD:INV.apiUrl+'api/Cuenta/RecuperarPassword',
        CAMBIARCONTRASENA:INV.apiUrl+'api/Cuenta/CambiarContrasena',
        LOGIN:INV.apiUrlLearning+'api/Cuenta/authenticate'
    },
    DIRECTORIO:{
        LISTAR:INV.apiUrl+'api/directorio/listar'
    },
    CONTACTENOS:{
        ENVIAR:INV.apiUrlLearning+'api/contactenos/enviar',
        GETALL:INV.apiUrlLearning+'api/contactenos'
    },
    CURSOS:{
        LISTAR:INV.apiUrlLearning+'api/curso/listar'
    },
    ALUMNO:{
        LISTAR:INV.apiUrlLearning+'api/alumno/listar',
        REGISTRAR:INV.apiUrlLearning+'api/alumno/registrar'
    },
    VISITANTE:{
        ENVIAR:INV.apiUrlLearning+'api/visitante',
        LOGS:INV.apiUrlLearning+'api/visitante/logs'
    }
    //... more of your variables
});
