<div>
  <mat-card>
    <mat-card-header>
    </mat-card-header>

    <mat-card-content class="text-center">
      <section id="web" class="pt-3">
        <h1>Desarrollo de Aplicaciones Web</h1>
        <h5>Desarrollamos aplicaciones web a medida de acuerdo a las necesidades de sus procesos de negocio.</h5>
        <img mat-card-image class="img-servicios" src="./assets/img/desarrollo-aplicaciones-web.jpg"
          alt="Desarrollo web">

        <!-- <div>
          <iframe scrolling="no" frameborder="0" allowtransparency="allowTransparency" width="280" height="65"
            src="http://www.facebook.com/widgets/like.php?width=280&show_faces=1&layout=standard&href=http://www.website.com/product/75&layout=button&action=like&size=small&share=true&height=65&appId">
          </iframe>
        </div> -->
      </section>
      <hr />
      <section id="movil" class="pt-3">
        <h1>Desarrollo de Aplicaciones Móviles</h1>
        <h5>Diseñamos y desarrollamos aplicaciones para Android al mejor precio del mercado</h5>
        <img mat-card-image class="img-servicios" src="./assets/img/desarrollo-apps-moviles.jpg"
          alt="Desarrollo Aplicaciones Móviles">

        <div>
          <!-- <iframe scrolling="no" frameborder="0" allowtransparency="allowTransparency" width="280" height="65"
            src="http://www.facebook.com/widgets/like.php?width=280&show_faces=1&layout=standard&href=http://www.website.com/product/75&layout=button&action=like&size=small&share=true&height=65&appId">
          </iframe> -->
        </div>
      </section>
      <hr />
      <section id="shopifyreact" class="pt-3">
        <h1>Desarrollo e integración de aplicaciones Shopify + React</h1>
        <h5>Desarrollamos conectores e integraciones de Shopify con servicios de Envío, ERP, CRM, Sistemas de Gestión, aplicaciones de pago, entre otros.</h5>
        <img mat-card-image class="img-servicios" src="./assets/img/shopify-react.png"
          alt="Integración de aplicaciones shopify">
        <div>
          <!-- <iframe scrolling="no" frameborder="0" allowtransparency="allowTransparency" width="280" height="65"
            src="http://www.facebook.com/widgets/like.php?width=280&show_faces=1&layout=standard&href=http://www.website.com/product/75&layout=button&action=like&size=small&share=true&height=65&appId">
          </iframe> -->
        </div>
      </section>
      <hr />
    </mat-card-content>
    <mat-card-actions class="text-center">

      <!-- <iframe scrolling="no" frameborder="0" allowtransparency="allowTransparency" width="280" height="65" src="http://www.facebook.com/widgets/like.php?width=280&show_faces=1&layout=standard&href=http://www.website.com/product/75"></iframe>  -->
      <!-- <button mat-button>LIKE</button>
          <button mat-button>SHARE</button> -->
    </mat-card-actions>
  </mat-card>
 
</div>