import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeIndexComponent } from './index/index.component';
import { HomeAboutComponent } from './about/about.component';
import {MatTabsModule} from '@angular/material/tabs';


@NgModule({
  declarations: [HomeIndexComponent, HomeAboutComponent],
  imports: [
    CommonModule,
    MatTabsModule
  ]
})
export class HomeModule { }
