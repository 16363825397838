<div class="container">
    <div class="row">
        <div class="col-sm-6" style="display: flex;align-items: center;padding: 10px;">
            <img src="../../../assets/img/servicio-al-cliente-e1565299571335.png" style="width: -webkit-fill-available;" />
        </div>
        <div class="col-sm-6" style="padding: 10px;">
            <div class="title">
                <h1>Contáctanos</h1>
            </div>
            <div class="content">
                <div lang="es-PE" dir="ltr">
                   
                    <form [formGroup]="formContacto" class="form-content" (ngSubmit)="postEnviar(formContacto.value)">
                        <p>
                            <mat-form-field appearance="legacy" class="ancho-100p" autocomplete="none">
                                <mat-label>Nombre</mat-label>
                                <input matInput formControlName="nombre" required>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="legacy"class="ancho-100p">
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="pat@example.com" formControlName="email" required>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="legacy"class="ancho-100p">
                                <mat-label>Teléfono</mat-label>
                                <input matInput formControlName="telefono">
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field appearance="legacy"class="ancho-100p">
                                <mat-label>Mensaje</mat-label>
                                <textarea matInput cdkAutosizeMinRows="2" 
                                cdkAutosizeMaxRows="5" formControlName="mensaje"
                                    required></textarea>
                            </mat-form-field>
                        </p>
                        <button mat-raised-button color="accent" 
                        style="float: right;" 
                        type="submit" [disabled]="!formContacto.valid || loading">Enviar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    
</div>