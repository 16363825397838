import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DirectorioIndexComponent } from './index/index.component';
import { RegistrarComponent } from './registrar/registrar.component';
import { DirectorioRoutingModule } from './directorio-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AlertComponent } from '../share/alert/alert.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DirectorioService } from '../services/directorio.service';
import { CatalogoService } from '../services/catalogo.service';
import { MatCardModule } from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import { MatProgressButtonsModule } from 'mat-progress-buttons';

@NgModule({
  declarations: [DirectorioIndexComponent, RegistrarComponent],
  imports: [
    CommonModule,
    DirectorioRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatCardModule,
    MatSelectModule,
    MatChipsModule,
    MatProgressButtonsModule
  ],
  providers:[AlertComponent,DirectorioService,CatalogoService]
})
export class DirectorioModule { }
