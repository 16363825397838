import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { VisitanteService } from 'src/app/services/visitante.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class HomeIndexComponent implements OnInit {

  constructor(private http:HttpClient, private visitanteService:VisitanteService) { 
    http.get("../../assets/token.json").subscribe(resp=>{
      
      localStorage.setItem("token",resp["token"]);
    });
    visitanteService.postEnviar("pantaleontec").subscribe(data=>{},error=>{console.log("Visitante:",error);})
  }

  ngOnInit(): void {
  }
  
}
