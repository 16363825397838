import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
    
@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit{
    message: any;

    constructor(private route:ActivatedRoute) { 
      this.message={
        resulttype:this.route.snapshot.paramMap.get('resulttype'),
        resulttext:this.route.snapshot.paramMap.get('resulttext')
      };
    }

    ngOnInit() {
    }
    
}