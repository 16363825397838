 import {Inject, Injectable} from "@angular/core";
 import { Router } from '@angular/router';
 
 @Injectable({
  providedIn: 'root'
})
 export class StorageService {
 
   constructor(private router: Router,
      @Inject('LOCALSTORAGE') private localStorage: Storage) {
    
   }
 
   setAuthenticate(token: any): void {
     this.localStorage.setItem('token', token);
   }
   getAuthenticated():any{
    var sessionStr = this.localStorage.getItem('token');
    var token=(sessionStr) ? sessionStr : {} as any;
    // var today=new Date();
    // if(token.expire<today){
    //   this.removeSession();
    //   return {} as any;
    // }
    // else{
      return token;
    //}
     
    // var isAuth = this.localStorage.getItem('authenticatedUser');
    // return isAuth=="1";
   }
   isAuthenticated(): boolean {
    var auth=this.getAuthenticated();
    return auth.token!=undefined;
  };
 
  
  removeSession(){
    this.localStorage.removeItem("token");
  }
 }
 