import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { AlertifyService } from 'src/app/services/alertify.service';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { DirectorioService } from 'src/app/services/directorio.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class DirectorioIndexComponent implements OnInit {

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Spinner Button',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: 'favorite'
    }
  };
  
  directorio:any[];
  catalogo:any[];
  tipoBusqueda:any[];
  formBusqueda:FormGroup;

  selectedValue:string;
  buscar:string;

  constructor(private _directorioService:DirectorioService,
    private _alert:AlertifyService,
    private _catalogoService:CatalogoService,
    private _formBuilder:FormBuilder) {
      
      this.directorio=undefined;
      this.catalogo=undefined;

      this.tipoBusqueda=[{value:0,name:"Negocios"},{value:1,name:"Productos"}];

      this.formBusqueda=_formBuilder.group({
        tipo:[0,[]],
        buscar:['',[Validators.required]],
      })
     }

  ngOnInit(): void {
  }
  postBuscar(form:any){
    this.spinnerButtonOptions.active = true;
    this.directorio=undefined;
    this.catalogo=undefined;
    if(form.tipo==0)
      this.listarDirectorio(form.buscar)
    else
      this.listarCatalogo(form.buscar);
  }
  listarDirectorio(buscar:string){
    
    this._directorioService.postListar(buscar).subscribe((data)=>{
      this.spinnerButtonOptions.active = false;
      this.directorio=JSON.parse(JSON.stringify(data));
    },
    (errorObject:any)=>{
      this.spinnerButtonOptions.active = false;
      this._alert.error(errorObject.error.resulttext);
    });
  }
  listarCatalogo(buscar:string){
    
    this._catalogoService.postListar(buscar).subscribe((data)=>{
      this.spinnerButtonOptions.active = false;
      this.catalogo=JSON.parse(JSON.stringify(data));
    },
    (errorObject:any)=>{
      this.spinnerButtonOptions.active = false;
      this._alert.error(errorObject.error.resulttext);
    });
  }
  formatDecimal(valor:any){
    return isNaN(valor) ? valor : parseFloat(valor).toFixed(2);
  }
}
