import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { CapacitacionesComponent } from "./capacitaciones/capacitaciones.component"
import { DesarrolloappComponent } from "./desarrolloapp/desarrolloapp.component"
import { DesarrollowebComponent } from "./desarrolloweb/desarrolloweb.component"
import { EfacturaComponent } from "./efactura/efactura.component"
import { PuntoventaComponent } from "./puntoventa/puntoventa.component"

const routes:Routes=[
    {
path:'',component:DesarrollowebComponent
    }
// {
//     path:'capacitaciones',component:CapacitacionesComponent
// },
// {
//     path:"desarrolloapp",component:DesarrolloappComponent
// },
// {
//     path:"desarrolloweb",component:DesarrollowebComponent
// },
// {
//     path:"efactura",component:EfacturaComponent
// },
// {
//     path:"puntoventa",component:PuntoventaComponent
// }
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortafolioRoutingModule{}