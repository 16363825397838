import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeIndexComponent } from './home/index/index.component';
import { DirectorioIndexComponent } from './directorio/index/index.component';
import { RegistrarComponent } from './directorio/registrar/registrar.component';
import { HomeAboutComponent } from './home/about/about.component';
import { ContactenosIndexComponent } from './contactenos/index/index.component';
import { Page404Component } from './share/page404/page404.component';
import { AlertComponent } from './share/alert/alert.component';
import { DetalleCursoComponent } from './cursos/detalle-curso/detalle-curso.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {path:"index",component:HomeIndexComponent},
  {path:"", redirectTo:"index", pathMatch:"full"},
  {path:"about",component:HomeAboutComponent},
  {path:"message/:resulttype/:resulttext",component:AlertComponent},
  { path:"directorio",
    loadChildren:()=> import('./directorio/directorio.module').then(m=>m.DirectorioModule)
  },
  { path:"contactenos",
    loadChildren:()=> import('./contactenos/contactenos.module').then(m=>m.ContactenosModule)
  },
  { path:"cuenta",
    loadChildren:()=> import('./cuenta/cuenta.module').then(m=>m.CuentaModule)
  },
  { path:"servicios",
    loadChildren:()=> import('./portafolio/portafolio.module').then(m=>m.PortafolioModule)
  },
  { path:"cursos",
    loadChildren:()=> import('./cursos/cursos.module').then(m=>m.CursosModule)
  },
  { path:"admin",
    loadChildren:()=> import('./admin/admin.module').then(m=>m.AdminLoginModule)
  },
  { path:"dashboard",
    loadChildren:()=> import('./dashboard/dashboard.module').then(m=>m.DashboardModule)
  },
  // {path:"directorio",component:DirectorioIndexComponent},
  // {path:"registrar",component:RegistrarComponent},
  // {path:"contactenos",component:ContactenosIndexComponent},
  {path:"**",component:Page404Component},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
