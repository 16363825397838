<mat-card class="mat-card-login">
    <div style="margin-left: auto;margin-right: auto;">
        <form id="login-form" #lForm="ngForm" [formGroup]="loginForm" (ngSubmit)="submitLogin(loginForm.value)" novalidate>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Enter your username</mat-label>
                    <input matInput placeholder="username" formControlName="username" required>
                    <mat-error *ngIf="loginForm.controls['username'].hasError('required')">Username es requerido</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Enter your password</mat-label>
                    <input matInput [type]="'password'" formControlName="password">
                    <mat-error *ngIf="loginForm.controls['password'].hasError('required')">Password es requerido</mat-error>
                </mat-form-field>
            </div>
        </form>
        <br />
        <button mat-raised-button color="primary" type="submit" form="login-form">Login</button>
    </div>

</mat-card>