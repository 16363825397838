<div class="container-cursos">
    <mat-card class="example-card">
        <mat-card-header>
          <div class="example-header-image">
            <img mat-card-image src="./assets/img/cursos/Microsoft-Office-365-Simbolo.png" alt="Microsoft Office 365">
          </div>
          <mat-card-title>Office 365</mat-card-title>
          <mat-card-subtitle>Microsoft Word, Excel y Power Point </mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image src="./assets/img/cursos/office 365.png" alt="Microsoft Office 365">
        <mat-card-content>
          <p>
            Capacítate en uno de los paquetes más importantes de oficina y hogar y adquiere el conocimiento avanzado de las herramientas de MS Word y Ms Excel para
    optimizar el tiempo en la generación de documentos, administrar, gestionar e interpretar información de
    bases de datos. Además prepara una de las mejores presentaciones en tu centro de trabajo.
          </p>
        </mat-card-content>
        <mat-card-actions>
            <iframe scrolling="no" frameborder="0" allowtransparency="allowTransparency" width="280" height="65"
            src="http://www.facebook.com/widgets/like.php?width=280&show_faces=1&layout=standard&href=http://www.website.com/product/75&layout=button&action=like&size=small&share=true&height=65&appId" >
            </iframe>
            <!-- <iframe scrolling="no" frameborder="0" allowtransparency="allowTransparency" width="280" height="65" src="http://www.facebook.com/widgets/like.php?width=280&show_faces=1&layout=standard&href=http://www.website.com/product/75"></iframe>  -->
          <!-- <button mat-button>LIKE</button>
          <button mat-button>SHARE</button> -->
        </mat-card-actions>
      </mat-card>
      <mat-card class="example-card">
        <mat-card-header>
          <div class="example-header-image">
            <img mat-card-image src="./assets/img/cursos/android.png" alt="Android Studio">
          </div>
          <mat-card-title>Android Development</mat-card-title>
          <mat-card-subtitle>Java code</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image src="./assets/img/cursos/android-studio-logo.png" alt="Android Studio">
        <mat-card-content>
          <p>
            ......
          </p>
        </mat-card-content>
        <mat-card-actions>
            <iframe scrolling="no" frameborder="0" allowtransparency="allowTransparency" width="280" height="65"
            src="http://www.facebook.com/widgets/like.php?width=280&show_faces=1&layout=standard&href=http://www.website.com/product/75&layout=button&action=like&size=small&share=true&height=65&appId" >
            </iframe>
        </mat-card-actions>
      </mat-card>
    </div>