<header >

    <app-navbar></app-navbar>

</header>
<div class="top-after-navbar">
    <!-- <img src="assets/img/foto1.jpg" alt=""> -->
</div>

<router-outlet></router-outlet>

<app-footer></app-footer>
