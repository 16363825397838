import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-efactura',
  templateUrl: './efactura.component.html',
  styleUrls: ['./efactura.component.scss']
})
export class EfacturaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
