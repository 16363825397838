<div class="register-area">
    
    <div class="container">
        <div style="background-color: yellow;color: blue;padding-left: 5px;padding-top: 15px;padding-right: 5px;padding-bottom: 2px;margin-bottom: 10px;text-align: center;">
            <h6>Regístrate y aprovecha ésta oportunidad para publicar hasta 2 productos gratis, y forma parte de nuestro directorio digital!!</h6>
        </div>
        <div class="register-title">
            <div class="border-title">
                <h5>Registrarse como:</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <!-- <ul class="register-tabnav" role="tablist">
                        <li role="presentation" class="active">
                            <a href="#regularuser" data-toggle="tab">
                                <span class="fas  fa-user"></span>
                                <div class="tg-navcontent">
                                    <h3>Cliente</h3>
                                    <span>Registrarse como cliente</span>
                                </div>
                            </a>
                        </li>
                        <li role="presentation">
                            <a href="#company" data-toggle="tab">
                                <span class="fas fa-building"></span>
                                <div class="tg-navcontent">
                                    <h3>Empresa</h3>
                                    <span>Registrar como empresa</span>
                                </div>
                            </a>
                        </li>
                    </ul> -->

                <mat-tab-group>
                    <!-- <mat-tab label="Emprendedor">
                        <form [formGroup]="registrarCuentaCliente"
                            (ngSubmit)="postFormCliente(registrarCuentaCliente.value)">
                            <div class="row" style="margin-top: 20px;">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="text-register">
                                            ¿Ya tienes una cuenta? Por favor Iniciar sesión.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 ">
                                    <div class="alert alert-warning p-0 text-center">
                                        Campos requeridos
                                    </div>
                                </div>
                                <div class="col-sm-6 ">
                                    <div class="form-group">
                                        <input class="form-control input-sm border-warning" type="text"
                                            formControlName="nombres" placeholder="Nombres">
                                        <span class="field-validation-valid" data-valmsg-for="Nombres"
                                            data-valmsg-replace="true"></span>
                                    </div>
                                </div>
                                <div class="col-sm-6 ">
                                    <div class="form-group">
                                        <input class="form-control input-sm border-warning" type="text"
                                            formControlName="apelpat" placeholder="Apellido Paterno">
                                        <span class="field-validation-valid" data-valmsg-for="Apellidos"
                                            data-valmsg-replace="true"></span>
                                    </div>
                                </div>
                                <div class="col-sm-6 ">
                                    <div class="form-group">
                                        <input class="form-control input-sm border-warning" type="text"
                                            formControlName="apelmat" placeholder="Apellido Materno">
                                        <span class="field-validation-valid" data-valmsg-for="Apellidos"
                                            data-valmsg-replace="true"></span>
                                    </div>
                                </div>

                                <div class="col-sm-6 ">
                                    <div class="form-group">
                                        <input class="form-control" type="text" formControlName="telefono"
                                            placeholder="Teléfono">
                                    </div>
                                </div>
                                <div class="col-sm-6 ">
                                    <div class="form-group">
                                        <input class="form-control" type="text" formControlName="movil"
                                            placeholder="Móvil">
                                    </div>
                                </div>
                                <div class="col-sm-12 ">
                                    <fieldset>
                                        <legend>Datos del usuario</legend>
                                        <div class="col-sm-6 ">
                                            <div class="form-group">
                                                <div class=" p-2 alert-warning">correo válido: nombre_cuenta@dominio.com
                                                </div>
                                                <input class="form-control input-sm border-warning" type="text"
                                                    formControlName="correo" placeholder="Email" ngModel [email]="true">
                                                <span class="field-validation-valid" data-valmsg-for="Correo"
                                                    data-valmsg-replace="true"></span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 ">
                                            <div class="form-group">
                                                <input class="form-control input-sm border-warning" type="password"
                                                    formControlName="contrasena" placeholder="Contraseña">
                                                <span class="field-validation-valid" data-valmsg-for="Contrasena"
                                                    data-valmsg-replace="true"></span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 ">
                                            <div class="form-group">
                                                <input class="form-control input-sm border-warning" type="password"
                                                    formControlName="repetirContrasena"
                                                    placeholder="Repetir Contraseña">
                                                <span class="field-validation-valid" data-valmsg-for="contrasena"
                                                    data-valmsg-replace="true"></span>
                                            </div>
                                            <label class="text-danger"
                                                *ngIf="registrarCuentaCliente.controls['repetirContrasena'].hasError('passwordsNotEqual')">
                                                Password no coinciden
                                            </label>
                                        </div>
                                        <div class="col-sm-12">
                                            <mat-checkbox [(ngModel)]="checked" formControlName="flagTerminos">Aceptar
                                                los términos y condiciones</mat-checkbox>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <button class="btn btn-primary" type="submit"
                                                    [disabled]="!registrarCuentaCliente.valid">
                                                    Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>

                            </div>
                        </form>
                    </mat-tab> -->


                    <mat-tab label="Empresa">
                        <form [formGroup]="registrarCuentaEmpresa"
                            (ngSubmit)="postFormEmpresa(registrarCuentaEmpresa.value)">
                            <div class="row" style="margin-top: 20px;">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="text-register">
                                            ¿Ya tienes una cuenta? Por favor Iniciar sesión.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="alert alert-warning p-0 text-center">
                                        Campos requeridos
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <mat-form-field class="ancho-100p">
                                        <mat-label>RUC</mat-label>
                                        <input matInput [type]="'text'"  formControlName="ruc" #ruc value="" required
                                        (keydown)="onKeyDownRuc($event)"/>

                                    </mat-form-field>
                                </div>
                                <div class="col-sm-2 col-md-2">
                                    <button mat-raised-button type="button" color='primary' (click)="postPadron(ruc.value)">Validar</button>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <mat-form-field class="ancho-100p">
                                        <mat-label>Razón Social</mat-label>
                                        <input matInput [type]="'text'" formControlName="razonSocial" value="" 
                                        readonly required/>
                                    </mat-form-field>

                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <mat-form-field class="ancho-100p">
                                        <mat-label>Dirección</mat-label>
                                        <input matInput formControlName="direccion" placeholder="Dirección">
                                      </mat-form-field>
                                        <input type="hidden" value="" name="Latitud" />
                                        <input type="hidden" value="" name="Longitud" />
                                </div>

                                <div class="col-sm-12 col-md-3">
                                    <mat-form-field class="ancho-100p">
                                        <mat-label>Teléfono</mat-label>
                                        <span matPrefix>+51 &nbsp;</span>
                                        <input type="tel" formControlName="telefono" matInput placeholder="999-9999">
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <mat-form-field class="ancho-100p">
                                        <mat-label>Móvil</mat-label>
                                        <span matPrefix>+51 &nbsp;</span>
                                        <input type="tel" formControlName="movil" matInput placeholder="999-999-999">
                                      </mat-form-field>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <fieldset>
                                        <legend>Datos del usuario</legend>
                                        <div class="col-sm-12 col-md-3">
                                            <mat-form-field class="ancho-100p">
                                                <mat-label>Email</mat-label>
                                                <input type="email" matInput formControlName="correo"
                                                placeholder="Ex. pat@example.com">
                                                <mat-error *ngIf="correo.hasError('email') && !correo.hasError('required')">
                                                  Por favor ingrese una dirección de correo válido.
                                                </mat-error>
                                                <mat-error *ngIf="correo.hasError('required')">
                                                  El Email <strong>es requerido</strong>
                                                </mat-error>
                                              </mat-form-field>
                                              
                                            <!-- <div class="form-group">
                                                
                                                <input class="form-control input-sm border-warning" type="text"
                                                    formControlName="correo" placeholder="Email" >
                                                <span class="field-validation-valid" data-valmsg-for="Correo"
                                                    data-valmsg-replace="true"></span>
                                            </div> -->
                                        </div>
                                        <div class="col-sm-12 col-md-3 ">
                                            <mat-form-field class="ancho-100p">
                                                <mat-label>Contraseña</mat-label>
                                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="contrasena" placeholder="Contraseña">
                                                <!-- <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"> -->
                                                <mat-icon mat-icon-button matSuffix (click)="hide=!hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                <!-- </button> -->
                                              </mat-form-field>
                                            <!-- <div class="form-group">
                                                <input class="form-control input-sm border-warning" type="password"
                                                    formControlName="contrasena" placeholder="Contraseña">
                                                <span class="field-validation-valid" data-valmsg-for="Contrasena"
                                                    data-valmsg-replace="true"></span>
                                            </div> -->
                                        </div>
                                        <div class="col-sm-12 col-md-3 ">
                                            <mat-form-field class="ancho-100p">
                                                <mat-label>Repetir Contraseña</mat-label>
                                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="repetirContrasena" placeholder="Repetir Contraseña">
                                                <!-- <mat-icon mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"> -->
                                                <mat-icon mat-icon-button matSuffix (click)="hide=!hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                <!-- </button> -->
                                              </mat-form-field>
                                            <!-- <div class="form-group">
                                                <input class="form-control input-sm border-warning" type="password"
                                                    formControlName="repetirContrasena"
                                                    placeholder="Repetir Contraseña">
                                            </div> -->
                                            <label class="text-danger"
                                                *ngIf="registrarCuentaEmpresa.controls['repetirContrasena'].hasError('passwordsNotEqual')">
                                                Password no coinciden
                                            </label>
                                        </div>

                                        <div class="col-sm-12">
                                            <mat-checkbox [(ngModel)]="checked" formControlName="flagTerminos">Aceptar
                                                los términos y condiciones</mat-checkbox>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <button class="btn btn-primary" type="submit"
                                            [disabled]="!registrarCuentaEmpresa.valid">
                                            Registrar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <!-- <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="register-title">
                    <div class="border-title">
                        <h3>Iniciar Sesión</h3>
                    </div>
                </div>
                <form [formGroup]="registrarCuentaEmpresa" (ngSubmit)="postFormEmpresa(registrarCuentaEmpresa.value)">
                    <div class="login-content row" style="padding: 15px 40px">
                        <div class="col-sm-12 ">
                            <div class="form-group">
                                <div class="text-register">
                                    Ingrese sus datos para acceder al sistema.
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="form-group">
                                <input class="form-control" type="text" name="Correo" placeholder="Cuenta de correo">
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <input class="form-control" type="text" name="Contrasena" placeholder="Contraseña">
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <button class="btn btn-success btn-login-now" type="button">
                                    Ingresar
                                </button>
                                <a mat-raised-button color="accent" [routerLink]="['/cuenta/recuperar']"
                                    title="Recuperar Contraseña">Recuperar Contraseña</a>

                            </div>

                        </div>
                    </div>
                </form>
            </div> -->
        </div>
    </div>
</div>