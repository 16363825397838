import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactenosIndexComponent } from './index/index.component';
import { ContactenosRoutingModule } from './contactenos-routing.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ContactenosService } from '../services/contactenos.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ContactenosIndexComponent],
  imports: [
    CommonModule,
    ContactenosRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers:[ContactenosService]
})
export class ContactenosModule { }
