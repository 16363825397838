import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desarrolloapp',
  templateUrl: './desarrolloapp.component.html',
  styleUrls: ['./desarrolloapp.component.scss']
})
export class DesarrolloappComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
