<mat-toolbar color="primary" class="mat-elevation-z6">
  <button mat-icon-button class="only-mobile" (click)="drawer.toggle()" style="color: #fff;">
    <mat-icon>menu</mat-icon>
  </button>
  <a [routerLink]="['']">
    <img src="assets/img/logo/logo_white.png" class="logo p-2" alt="">
  </a>

  <div class="spacer"></div>
  <div class="only-desktop">
  <a mat-button [matMenuTriggerFor]="menu" class="vertical-center">Inicio</a>
  <a mat-button href="https://godavidstore.pe" target="_blank" class="vertical-center">
    <img src="assets/img/logo/gds-256x128.png" class="p-2" style="height: 53px;"  class="vertical-center">
  </a>
  <a mat-button [routerLink]="['/servicios']" class="vertical-center">Servicios</a>
  <a mat-button [routerLink]="['contactenos']" class="vertical-center">Contáctanos</a>
</div>
  <div class="spacer"></div>
  <!-- <a mat-raised-button class="buttons-toolbar" color="accent" [routerLink]="['directorio/registrar']"
    title="Resgístrate">Registrarme</a>
  <a mat-raised-button class="buttons-toolbar login-button" routerLink="http://localhost:4200/"
    title="Ingresa a tu cuenta">Log In</a> -->
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="['']">Inicio</button>
  <button mat-menu-item [routerLink]="['about']">Nosotros</button>
</mat-menu>
<mat-menu #cursos="matMenu" xPosition="after">
  <button mat-menu-item [routerLink]="['/cursos/full-stack-developer']">Full Stack Developer</button>
  <!-- <button mat-menu-item [routerLink]="['/cursos/microsoft-excel']">Microsoft Excel</button> -->
</mat-menu>
<!-- <mat-menu #menu_serv="matMenu" xPosition="after">
  <button mat-menu-item [routerLink]="['/servicios/desarrolloweb']">Desarrollo de aplicaciones Web</button>
  <button mat-menu-item [routerLink]="['/servicios/desarrolloapp']">Desarrollo de aplicaciones Móviles</button>
  <button mat-menu-item [routerLink]="['/servicios']">Directorio</button>
</mat-menu> -->

<!-- Contenedor del sidenav -->
<mat-drawer-container class="sidenav-container" autosize>
  <!-- <mat-sidenav-container class="sidenav-container"  autosize> -->
    <!-- Sidenav flotante que se superpone sobre el contenido -->
    <!-- <mat-sidenav #sidenav mode="side" class="sidenav"> -->
      <mat-drawer #drawer class="sidenav" mode="side">
      <mat-nav-list>
        <a mat-list-item [matMenuTriggerFor]="menu" (click)="drawer.close()" style="color: #fff;">Inicio</a>
        <a mat-list-item href="https://godavidstore.pe" target="_blank"  (click)="drawer.close()">
          <img src="assets/img/logo/gds-256x128.png" class="p-2" style="height: 61px;" alt="">
        </a>
        <a mat-list-item [routerLink]="['/servicios']" (click)="drawer.close()" style="color: #fff;">Servicios</a>
        <a mat-list-item [routerLink]="['contactenos']" (click)="drawer.close()" style="color: #fff;">Contáctanos</a>
        <!-- <a mat-list-item href='#inicio-section' (click)="drawer.close()" style="color: #fff;">INICIO</a>
        <a mat-list-item href='#planes-section'(click)="drawer.close()" class="color-white">PRECIOS</a>
        <a mat-list-item href='https://uat-erp.godavidstore.pe/edoc/consultar' target="_blank" (click)="drawer.close()" class="color-white">CONSULTAR COMPROBANTE</a>
        <a mat-list-item href='#contactenos-section' (click)="drawer.close()" class="button-demo color-white">SOLICITA UNA DEMO</a> -->
  
      </mat-nav-list>
    </mat-drawer>
    <!-- </mat-sidenav> -->
  <!-- </mat-sidenav-container> -->
  </mat-drawer-container>