import { Component, OnInit } from '@angular/core';
import { CursoI } from 'src/app/models/curso.interface';
import { AlertifyService } from 'src/app/services/alertify.service';
import { CursosService } from 'src/app/services/cursos.service';

@Component({
  selector: 'app-capacitaciones',
  templateUrl: './capacitaciones.component.html',
  styleUrls: ['./capacitaciones.component.scss']
})
export class CapacitacionesComponent implements OnInit {

  jsonCursos:CursoI[];
  constructor(private _cursosService:CursosService,
    private _alert:AlertifyService) { 
      this.listarCursos();
  }

  ngOnInit(): void {
  }

  listarCursos() {
    this._cursosService.postListar().subscribe(
      (data) => {

        var lista: CursoI[] = JSON.parse(JSON.stringify(data));
        this.jsonCursos = lista;
      },
      (errorObject: any) => {
        if(errorObject.statusText)
          this._alert.error(errorObject.statusText);
        else
          this._alert.error(errorObject.error.resulttext);
      });
  }

}
